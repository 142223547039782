import GA4React from "ga-4-react";

export const initGA = (trackingId: string | any) => {
    if (trackingId === null || trackingId === '')
        return;

    try {
        setTimeout(_ => {
            const ga4react = new GA4React(trackingId);
            ga4react.initialize().catch(err => console.error(err));
        }, 4000);
    } catch (err) {
        console.error(err);
    }
}
export const Event = (category: string, action: string, label: string) => {
    
};
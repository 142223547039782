import './spinner.css';

function Spinner() {
  return (
    <div className="custom-message">
      <div className="loader"></div>
      <div className="loaderText">Fetching data ...</div>
    </div>
)}

export default Spinner
import * as ACTION_TYPES from '../actions/action_types'

interface ISearchState {
  search_textChange: string,
  search_textSubmit: string,
}

export const initialState: ISearchState = {
  search_textChange: '',
  search_textSubmit: ''
}

export const SearchReducer = (state: ISearchState, action: any) => {
    switch(action.type) {
      case ACTION_TYPES.SEARCH_INPUT_CHANGE:
        return {
          ...state,
          search_textChange: action.payload
        }
      case ACTION_TYPES.SEARCH_INPUT_SUBMIT:
        return {
          ...state,
          search_textSubmit: action.payload
        }
      default:
        throw new Error();
    }
}
function timeDifference(current: number, previous: number): string {
    const milliSecondsPerMinute = 60 * 1000
    const milliSecondsPerHour = milliSecondsPerMinute * 60
    const milliSecondsPerDay = milliSecondsPerHour * 24
    const milliSecondsPerMonth = milliSecondsPerDay * 30
    const milliSecondsPerYear = milliSecondsPerDay * 365
  
    const elapsed = current - previous
  
    if (elapsed < milliSecondsPerMinute / 3) {
      return 'just now'
    }
  
    if (elapsed < milliSecondsPerMinute) {
      return 'minder dan 1 minuut geleden'
    } else if (elapsed < milliSecondsPerHour) {
      return Math.round(elapsed / milliSecondsPerMinute) + ' minuten geleden'
    } else if (elapsed < milliSecondsPerDay) {
      return Math.round(elapsed / milliSecondsPerHour) + ' uur geleden'
    } else if (elapsed < milliSecondsPerMonth) {
      return Math.round(elapsed / milliSecondsPerDay) + ' dagen geleden'
    } else if (elapsed < milliSecondsPerYear) {
      return Math.round(elapsed / milliSecondsPerMonth) + ' maanden geleden'
    } else {
      return Math.round(elapsed / milliSecondsPerYear) + ' jaar geleden'
    }
  }
  
  export function timeDifferenceForDate(date: Date): string {
    const now: number = new Date().getTime()
    const updated: number = new Date(date).getTime()
    return timeDifference(now, updated)
  }

export function GetDateTime(date: Date | undefined): string {
  if (date === undefined || date === null)
    return '';

  let dt = new Date(date);
  return new Intl.DateTimeFormat('nl-NL', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(dt);
}
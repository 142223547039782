import { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

import { timeDifferenceForDate } from '../../utils/date';
import Spinner from '../spinner';
import Error from '../error';
import ContentContext from '../../context/context';
import { Link } from 'react-router-dom';

// import '../../styles/Category.css';

interface ArticleDetailProps {
    category: string,
    id: string,
    name: string,
    option: string,
}

const GET_ARTICLE = gql`
query getArticle($id: Int!) {
    articles(id: $id) {
        articleId
        title
        description
        content
        keywords
        publishDate
        lastUpdated
        path
        categories {
            categoryId
            name
            icon
            isDefault
        }
    }
    categories {
        categoryId
        name
    }
}`;

function ArticleDetail(props: RouteComponentProps<ArticleDetailProps>) {
    const context = useContext(ContentContext)
    
    return (
        <Query query={GET_ARTICLE} variables={{id: parseInt(props.match.params.id) }}>
        {(result: any) => {

            const { data, loading, error } = result;

            if (loading) return <Spinner />
            if (error) return <Error error={error} />
            
            const article = data.articles[0];

            function createMarkup() {
                return {__html: article.content};
            }

            return (
                <div className="tall_panel">
                    <Helmet 
                        htmlAttributes={{ lang: 'nl' }} 
                        title={`.: cmiddelweerd | ${article.title}`} 
                        meta={[
                            {
                                name: 'description',
                                content: article.description,
                            },
                            {
                                name: 'keywords',
                                content: article.keywords,
                            },
                        ]} 
                        link={[
                            {
                                 rel: 'canonical',
                                 href: 'www.ergens.nl',
                            },
                        ]} />
                    <div className="title">
                        {article.title}
                    </div>
                    <div className="description">
                        {article.description} 
                    </div>
                    <div className="description" dangerouslySetInnerHTML={createMarkup()}>
                    </div>
                    <div className="description">
                        Laatste wijziging: {timeDifferenceForDate(article.lastUpdated)}<br />
                        Gepubliceerd: {timeDifferenceForDate(article.publishDate)}
                    </div>
                    <div className="categories">{ 
                        article.categories.map((category: { name: React.ReactNode; }, categoryIndex: number) => 
                            <div className={categoryIndex === 0 ? 'category': 'keywords'} 
                                key={`category_${categoryIndex}`}>{categoryIndex === 0 ? `` : ` | `}{category.name}</div> 
                        )}</div>
                    {context.authState ?
                    <div className="options">
                        <Link to={`/artikelen/admin/edit/${article.path}`}>bewerken</Link>
                    </div>
                    : null}

                </div>

            )
        }}
        </Query>)
}

export default ArticleDetail
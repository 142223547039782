import { createContext } from 'react'
import Auth from '../components/login/auth'
import Profile from '../store/types/profile'

interface IContentContext {
  searchString: string,
	useContextChangeState: string;
	useContextSubmitState: () => void;
  useContextSubmit: (event: any) => void;
  useContextChange: (event: any) => void;

  authState: boolean;
  profileState: Profile;

  handleUserLogin: () => void;
  handleUserLogout: () => void;

  handleAuth: (props: any) => void;
  authObj: Auth;
}

// const warningObject = {
// 	get foo() {
// 		throw new Error('You probably forgot to put <MyProvider>.');
// 	},
// };

//const ContentContext = createContext<Partial<IContentContext>>({})
const ContentContext = createContext({} as IContentContext)

export default ContentContext

/*

export default ContentContext = createContext(warningObject)

//export const ContentProvider = ContentContext.Provider


*/

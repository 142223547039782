import '../styles/App.css';
import ContextState from '../context/context_state_config'

const App = () => {
  return(
    <div>
      <ContextState />
    </div>
  )
}

export default App
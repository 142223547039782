import { Authenticate } from '../../utils/comm';
import history from '../../context/history';
import { AUTH_TOKEN } from '../../constants';



export default class Auth {
  userProfile = {}

  login = () => {}

  handleAuth = async (email: string, password: string) => {
    const user = await Authenticate(email, password);

      if(user.token) {
        localStorage.setItem(AUTH_TOKEN, user.token)
        localStorage.setItem('id_token', user.token)

        let expiresAt = JSON.stringify(3600*1000 + new Date().getTime());//(authResult.expiresIn * 1000 + new Date().getTime()))
        localStorage.setItem('expiresAt', expiresAt)

        this.getProfile();
        setTimeout(() => { history.replace('/authcheck') }, 600);
      } else {
        console.log('Login failuer')
      }
  }

  getAccessToken = () => {
    if(localStorage.getItem(AUTH_TOKEN)) {
      const accessToken = localStorage.getItem(AUTH_TOKEN)
      return accessToken
    } else {
      return null
    }
  }


  getProfile = () => {
    let accessToken = this.getAccessToken()
    if(accessToken) {
    //   this.auth0.client.userInfo(accessToken, (err, profile) => {
    //       if(profile) {
    //         this.userProfile = { profile }
    //       }
    //   } )
    }
  }


  logout = () => {
    console.log('logout in progress')
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem('id_token')
    localStorage.removeItem('expiresAt')
    setTimeout(() => { history.replace('/authcheck') }, 200);
  }

  isAuthenticated = () => {
    let expiresAt = localStorage.getItem('expiresAt');

    if (expiresAt == null)
    {
      return false;
    } 
    else 
    {
      let expires = JSON.parse(expiresAt)
      return new Date().getTime() < expires
    }
  }
}
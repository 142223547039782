export const SUCCESS = "SUCCESS"

export const FAILURE = "FAILURE"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGIN_FAILURE = "LOGIN_FAILURE"

// export const ADD_PROFILE = "ADD_PROFILE"

// export const REMOVE_PROFILE = "REMOVE_PROFILE"

export const SEARCH_INPUT_CHANGE = "SEARCH_INPUT_CHANGE"

export const SEARCH_INPUT_SUBMIT = "SEARCH_INPUT_SUBMIT"
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import ContentContext from '../../context/context'

import './login.css'

function Login() {
  const context = useContext(ContentContext)    
  const [state, setState] = useState({
      login: true, // switch between Login and SignUp
      name: '',
      email: '',
      password: '',
      redirectTo: null,
    });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;    
      setState(prevState => ({
          ...prevState,
          [name]: value
      }));
  };  

  const _confirm = async () => {
    context.authObj.handleAuth(state.email, state.password);
  }

  return (
      <div>
        <Helmet>
          <title>.: cmiddelweerd | Inloggen</title>
          <meta name="description" content="Inloggen" />
        </Helmet>
        <main className="content">

          <h4 className="mv3">{state.login ? 'Login' : 'Sign Up'}</h4>
          <div className="flex flex-column">
            {!state.login && (
              <input
                name="name"
                value={state.name}
                onChange={handleChange}
                type="text"
                placeholder="Your name"
              />
            )}
            <input
              name="email"
              value={state.email}
              onChange={handleChange}
              type="text"
              placeholder="Your email address"
            />
            <input
              name="password"
              value={state.password}
              onChange={handleChange}
              type="password"
              placeholder="Choose a safe password"
            />
          </div>
          <p />
          <div>
            <div className="button" onClick={() => _confirm()}>
              {state.login ? 'login' : 'create account'}
            </div>
            <div
              className="button"
              onClick={() => {}}
            >
              {state.login
                ? 'need to create an account?'
                : 'already have an account?'}
            </div>
          </div>
        </main>
      </div>
    )
}

export default Login
import { IArticleRequest } from "../store/interfaces";
import { AUTH_TOKEN } from "../constants";

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

const requestOptions: RequestInit = { 
  mode: "cors", // no-cors, cors, *same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "omit", // include, *same-origin, omit
  headers: {
      "Content-Type": "application/json",
  },
  redirect: "follow", // manual, *folslow, error
  referrer: "client", // no-referrer, *client
}

export async function http<T>(
    request: RequestInfo
  ): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(
      request
    );
  
    try {
      // may error if there is no body
      response.parsedBody = await response.json();
    } catch (ex) {}
  
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
}

export async function get<T>(
    path: string,
    args: RequestInit = { method: "get" }
  ): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};
  
export async function post<T>(
    path: string,
    body: any,
    args: RequestInit = { 
      method: "post", 
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "omit", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *folslow, error
      referrer: "client", // no-referrer, *client
      body: JSON.stringify(body) }
  ): Promise<HttpResponse<T>>  {
    return await http<T>(new Request(path, args));
};
  
export async function put<T>(
    path: string,
    body: any,
    args: RequestInit = { method: "put", body: JSON.stringify(body) }
  ): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};

export interface IUser {
  id: number
  password: null
  token: string
  userName: string
}

export async function Authenticate(username: string, password: string) : Promise<IUser> {
  var url = `${process.env.REACT_APP_API_URL}/api/v1/users`;
  var data = {username: username, password: password }

  requestOptions.body = JSON.stringify(data);
  requestOptions.method = "POST";

  const response = await post(url, data, requestOptions);

  console.log(response.parsedBody);

  return response.parsedBody as IUser;
}

export async function isAuthenticated() {
  const token = localStorage.getItem(AUTH_TOKEN);
  const url = `${process.env.REACT_APP_API_URL}/api/v1/users/isauthenticated?token=${token}`;

  if (token === null) {
    return false;
  }

  requestOptions.method = "GET";

  await get(url, requestOptions)
    .then((result) => {
      if (!(result.parsedBody as boolean)) {
        console.log("isAuthented logged out");
        localStorage.removeItem(AUTH_TOKEN);
      }
    })
    .catch((error) => {
      console.log("isAuthented error", error);
      localStorage.removeItem(AUTH_TOKEN);
    });
}

export async function PersistArticle(article: IArticleRequest) : Promise<boolean> {
  let url = `${process.env.REACT_APP_API_URL}/api/v1/articles`;

  let formData = jsonToFormData(article, 'article');

  let requestOptions: RequestInit = { 
    method: "post", 
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
 //       "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem(AUTH_TOKEN)
      },
    redirect: "follow", // manual, *folslow, error
    referrer: "client", // no-referrer, *client
    body: formData,
  }

  const response = await post(url, null, requestOptions)

  if (!response.ok) {
    console.log(response.statusText);
  }

  return response.ok;
}

export async function UploadFile(articleId: number, files: FileList) {
  let url = `${process.env.REACT_APP_API_URL}/api/v1/articles/${articleId}/upload`;
  let formData = new FormData();

  for (let i=0; i < files.length; i++) {
    formData.append(`files`, files[i]);
  }

  let requestOptions: RequestInit = { 
    method: "post", 
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
      //"Content-Type": "application/octet-stream",
      //"Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + localStorage.getItem(AUTH_TOKEN)
    },
    redirect: "follow", // manual, *folslow, error
    referrer: "client", // no-referrer, *client
    body: formData,
   }

  const response = await post(url, null, requestOptions)

  if (!response.ok) {
    console.log(response.statusText);
  }

  return response.parsedBody as boolean;
}

function buildFormData(formData: FormData, data: any, parentKey: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key, index) => {
      if (data instanceof Array) {
        buildFormData(formData, data[key as any], 
          (parentKey ? `${parentKey}[${index}]` : key));
      }
      else {
        buildFormData(formData, data[key], 
          (parentKey ? `${parentKey}.${key}` : key));
      }
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: any, parentKey: string) : FormData {
  const formData = new FormData();

  buildFormData(formData, data, parentKey);

  return formData;
}
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function About () {
  useEffect(() => {
    window.history.replaceState(null, '', "/about");
  }, []);

  return (
      <div>
        <Helmet>
          <title>.: cmiddelweerd | Over ...</title>
          <meta name="description" content="Over mijzelf en deze website" />
        </Helmet>

        <h1>Over mij</h1>
        <p>
          Deze website is het resultaat van mijn achtegrond als software ontwikkelaar: 
          Ik ben een C#, Azure certified (web) developer met brede interesses. 
          Het ontbrak mij nog aan een (online) plek, waar ik mijn (opgedane) kennis kan uitproberen en ik beschrijf wat ik heb kunnen leren. 
          Het is enkel en alleen voor mijzelf bedoeld, maar wellicht dat jij toch net dat stukje informatie vind, om je verder te helpen bij jouw probleem.
        </p>

        <p>
          Wat is mijn motiviatie en wat ik wil bereiken is dat: 
        </p>
        <ul>
          <li>Ik wil iedere dag een brug kunnen zijn tussen business en it</li>
          <li>Een toegevoegde waarde zijn voor mensen en waarde toevoegen aan bedrijven</li>
          <li>Een professional zijn in mijn werk</li>
        </ul>

        <p>
          Wat vind ik (iedere dag weer) belangrijk:
        </p>
          <ul>
            <li>Een agile mindset hebben, verbeter iedere dag (iets)</li>
            <li>Proactief zijn maar wel blijven luister en observeren</li>
            <li>Coachende lead zijn (lead by example, just do it)</li>
            <li>Integer zijn</li>
            <li>Plezier hebben/maken (een beetje ondeugend zijn)</li>
          </ul>


        <h1>Over deze website</h1>
        <p>
          Op deze website maakt gebruik van heel veel verschillende technieken, desing patters, overwegingen en een veelvoud van gebruikte oplossingen.
          Op de back end maak ik gebruik van o.a. .Net Core, GraphQL, HATEOAS, CQRS, Dapper.Net en Entity Framework.
          En de front is gerealiseerd met React.
        </p>
        <p>
          Wat ik heb geleerd vind je in de artikelen die ik heb geschreven met verwijzingen waar ik het heb gevonden. \
          Op deze manier heb ik mijzelf heel wat kunnen leren.
        </p>

      </div>
  )
}

export default About
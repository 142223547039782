import { useContext } from 'react';
import { Query } from 'react-apollo';
import { Link, RouteComponentProps } from 'react-router-dom';

import { IArticle, ICategory } from '../../store/interfaces';
import { Event } from '../tracking';
import ContentContext from '../../context/context';
import Error from '../error';
import Spinner from '../spinner';

import './article-list.css';

import { gql } from "apollo-boost";

const GET_ARTICLES = gql`{
    articles {
      articleId
      path
      title
      description
      categories {
        categoryId
        name
        icon
        url
      }
    }
}`; 

interface ArticleListRoutingProps {
    category: string,
} 

function ArticleList(props: RouteComponentProps<ArticleListRoutingProps>) {
    const context = useContext(ContentContext)
        
    return (
        <Query query={GET_ARTICLES}>
            {(result: any) => {
                const { data, loading, error } = result;

                if (loading) return <Spinner />
                if (error) return <Error error={error} />

                const category = props.match.params.category || '';

                const articles = data.articles.filter((item: IArticle) => {
                    let content = item.title.concat(item.description);
                    let searchString = (context.useContextChangeState || '').toLowerCase();

                    let foundByCategory = category === '' || item.categories.some((c: ICategory) => (c.url || '') === category);

                    return content.toLowerCase().search(searchString) !== -1 && foundByCategory;
                })

                return  (
                <div className="article_list">
                { articles.map((article: IArticle, articleIndex: number) => 
                    <div className={`article ${articleIndex === 0 ? 'tall_panel': 'panel'}`} 
                        id={`article_item_${article.articleId}`} key={`article_item_${article.articleId}`}>
                        <div className={`title`}>
                            <Link to={`/artikelen/${article.path}`}
                            onClick={() => {
                                Event(
                                    `${article.categories[0].name}`, 
                                    `Title ${article.title}`, 
                                    `Article title ${article.title}`);
                                window.location.href= `/artikelen/${article.path}`;
                            }}>
                                {article.title}</Link>
                        </div>
                        <div className={`description`}>
                            { article.categories[0].icon !== null
                                ? <Link to={`/artikelen/${article.path}`}
                                    className={`link`}
                                    onClick={() => {
                                        Event(
                                            `${article.categories[0].name}`, 
                                            `Image ${article.title}`, 
                                            `Article image ${article.title}`);
                                        window.location.href= `/artikelen/${article.path}`;
                                    }}>
                                        <img src={`/icons/${article.categories[0].icon}`} 
                                            className="icon" alt={`Categorie ${article.categories[0].name}`} />
                                    </Link>
                                : <></>}
                            {article.description} 
                            <Link to={`/artikelen/${article.path}`}
                                className={`link`} 
                                onClick={() => {
                                    Event(
                                        `${article.categories[0].name}`, 
                                        `More ${article.title}`, 
                                        `Article more ${article.title}`);
                                    window.location.href= `/artikelen/${article.path}`;
                                }}>
                                .: verder lezen :.</Link>
                        </div>
                        <div className={`categories`}>{ 
                            article.categories.map((category: ICategory, categoryIndex: number) => 
                                <div className={categoryIndex === 0 ? 'category': 'keywords'} 
                                    key={`category_${articleIndex}_${categoryIndex}`}>{categoryIndex === 0 ? `` : ` | `}{category.name}</div> 
                            )}
                        </div>
                        { context.authState ?
                            <div className="options">
                                <Link to={`/artikelen/admin/edit/${article.path}`}>bewerken</Link>
                            </div>
                        : null}
                    </div>
                )}
                </div>)
            }}
        </Query>)
}

export default ArticleList
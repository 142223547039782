import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import ReactQuill from 'react-quill';

import { IArticleRequest, ICategory } from '../../store/interfaces';
import { PersistArticle } from '../../utils/comm';
import Error from '../error';
import Spinner from '../spinner';

import 'react-quill/dist/quill.snow.css';
import './edit-article-detail.css'
import { gql } from "apollo-boost";

const GET_CATEGORIES = gql`{
  categories {
    categoryId
    name
    url
    isDefault
  }
}`;


interface NewArticleDetailProps {
}

interface IArticleNewState {
  articleId: number,
  title: string,
  description: string,
  content: string,
  keywords: string,
  categories: ICategory[]
}

function NewArticleDetail(props: RouteComponentProps<NewArticleDetailProps>) {
  console.log('Running New article()')

  const { loading, error, data } = useQuery(GET_CATEGORIES);
  const [ state, setState ] = useState<IArticleNewState>(
  {
    articleId: -1,
    title: '',
    description: '',
    content: '',
    categories: [ { categoryId: 1, name: 'test', isDefault: true } ],
    keywords: '',
  });

  const [ categoryList, setcategoryList ] = useState<ICategory[]>([{ categoryId: 1, name: '', icon: '', isDefault: true }]);

  const handleChange = (e: any) => {
      const { name, value } = e.target;  
      setState({
        ...state,
        [name]: value
      });
  };  

  const handleCategoryChange = (id: number) => {
    const isFound = state.categories.filter((item: ICategory, index) =>
      item.categoryId === id
    ).length > 0;

    const newList = state.categories.filter((item: ICategory) => item.categoryId !== id);

    if (!isFound)
    {
      newList.push({
        categoryId: id, 
        isDefault: false})
    }

    setState({
      ...state,
      categories: newList
    })
  }

  const handleMainCategoryChange = (id: number) => {
    const newList = state.categories.filter((item: ICategory) => item.isDefault === false);

    const newMain = categoryList.filter((item: ICategory, index) =>
      item.categoryId === id
    )[0];

    newList.push({
      categoryId: newMain.categoryId, 
      isDefault: true})
    
    setState({
      ...state,
      categories: newList
    })
  }

  const _confirm = async () => {
    const request: IArticleRequest = {
      articleId: -1,
      title: state.title,
      description: state.description,
      content: state.content,
      categories: state.categories,
      keywords: state.keywords,
    }

    const articleResponse = await PersistArticle(request)

    console.log("Response ", articleResponse);

    if (articleResponse) {
      window.location.href= "/artikelen/admin";
    }
  }

  useEffect(() => {
    if(loading === false && data){
      setcategoryList(data.categories);
    }
  }, [loading, data])

  if (loading) return <Spinner />
  if (error) return <Error error={error} />

  return (
         <div>
          <div>
            <label htmlFor="title">Title</label>
            <input type="text" id="title" name="title" placeholder="title"
              value={state.title}
              onChange={handleChange} />
          </div>

          <div>
            <label htmlFor="description">Description</label>
            <textarea id="description" name="description" placeholder="description"
              value={state.description} 
              onChange={handleChange} />
          </div>

          <div>
            <label htmlFor="keywords">Keywords</label>
            <input type="text" id="keywords" name="keywords" placeholder="keywords"
              value={state.keywords}
              onChange={handleChange} />
          </div>

          <div>
            <label htmlFor="mainCategory">Main category</label>
            <select name="main" id="mainCategory"
              onChange={(e) => handleMainCategoryChange(parseInt(e.target.value))}>
              {categoryList.map((item, i) => (
                <option key={i} value={item.categoryId} 
                selected={ state.categories.filter((category : ICategory) => category.categoryId === item.categoryId && category.isDefault ).length > 0 }>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="categoryList">Categories</label>
            <fieldset className="checkboxgroup">
              {categoryList.map((item, i) => (
                <div className="checkbox" key={i} >
                    <label>
                        <input 
                          type="checkbox" name="categories" value={item.categoryId}
                          checked={ state.categories.filter((category : ICategory) => category.categoryId === item.categoryId && !category.isDefault ).length > 0 }
                          onChange={() => handleCategoryChange(item.categoryId)}                          
                        />
                        {item.name}
                    </label>
                </div>
              ))}
            </fieldset>
          </div>

          <div>
            <label htmlFor="content">Content</label>
            <div className="editor">
            <ReactQuill value={state.content} className="contentEditor" 
              modules={modules}
              formats={formats}
              onChange={(text: string, delta: any, source: string, editor: any) => {
                if (source === 'user') {
                  // place whatever function you want to execute when user types here:
                  setState({
                    ...state,
                    content: text
                  })
                }
              }} />
            </div>
          </div>

          <div>
            <label htmlFor="articleId">articleId</label>
            <input type="text" id="articleId" name="articleId"
              disabled
              value='NIEUW' />
          </div>


          <div className="">
            <div className="button" onClick={() => _confirm()}>save
            </div>
          </div>
         </div>
  )
}

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export default NewArticleDetail
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import { IArticle } from '../../store/interfaces';
import { GetDateTime } from '../../utils/date';

import Error from '../error';
import Spinner from '../spinner';
import { gql } from "apollo-boost";

const GET_ARTICLES = gql`{
    articles {
      articleId
      path
      title
      description
      categories {
        categoryId
        name
        icon
        url
      }
    }
}`; 

function EditArticleList() {//props: Route<EditArticleListRoutingProps>) {
    console.log('Running EditArticleList()')

    return (
        <Query query={GET_ARTICLES}>
            {(result: any) => {
                const { data, loading, error } = result;

                if (loading) return <Spinner />
                if (error) return <Error error={error} />
                
                return  (
                    <div>
                        <Link to={`/artikelen/admin/new`}
                            onClick={() => {
                                window.location.href= `/artikelen/admin/new`;
                            }}>Artikel toevoegen</Link>
                        <table>
                            <thead>
                            <tr>
                                <td>Id</td>
                                <td>Titel</td>
                                <td>Publicatie</td>
                                <td>&nbsp;</td>
                            </tr>
                            </thead>
                            <tbody>
                            {data.articles.map((article: IArticle, articleIndex: number) => (
                            <tr key={`article_row_${articleIndex}`}>
                                <td>{article.articleId}</td>
                                <td>{article.title}</td>
                                <td>{GetDateTime(article.publishDate)}</td>
                                <td>
                                    <Link to={`/artikelen/admin/edit/${article.articleId}`}
                                        onClick={() => {
                                            window.location.href= `/artikelen/admin/edit/${article.articleId}`;
                                          }}
                                    >bewerk</Link>
                                    { article.publishDate === null
                                        ? <> | <a href="publiceer">publiceer</a></>
                                        : <></>
                                    }                                
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )
            }}
        </Query>)
}

export default EditArticleList
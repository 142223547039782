import { useContext } from 'react'
import { Router, Route, Switch, Redirect, RouteProps } from 'react-router'
import { Helmet } from 'react-helmet';

import ContentContext from './context/context'
import history from './context/history'

import About from './components/About'
import Page404 from './components/404'
import { initGA } from './components/tracking';
import ArticleList from './components/article-list';
import ArticleDetail from './components/article-detail';
import AuthCheck from './context/authcheck';
import EditArticleList from './components/edit-article-list';
import EditArticleDetail from './components/edit-article-detail';
import NewArticleDetail from './components/new-article-detail';
import CategoryList from './components/category-list';
import CategoryDetail from './components/category-detail';
import Header from './components/header';
import Login from './components/login';
import Empty from './components/empty';

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
} & RouteProps;

export function ProtectedRoute({isAuthenticated, authenticationPath, ...routeProps}: 
  ProtectedRouteProps) {
  if(isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
};

const Routes = () => {
  initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  const context = useContext(ContentContext)

  return(
    <div>
      <Router history={history}>
        <Helmet>
          <title>.: cmiddelweerd</title>
          <meta name="description" content="Welkom bij cmiddelweerd.nl" />
        </Helmet>

        <header className="header">
          <Header />
        </header>

        <aside className="leftSidebar">
          <Route exact path="/" component={CategoryList} />
          <Switch>
            <Route exact path="/artikelen/admin" component={Empty} />
            <Route exact path="/artikelen/:category" component={CategoryDetail} />
          </Switch>
        </aside>

        <main className="content">
          <Switch>
           <ProtectedRoute path='/artikelen/admin' exact 
              authenticationPath='/login'
              isAuthenticated={context.authObj.isAuthenticated()}
              component={EditArticleList} />
            <ProtectedRoute path='/artikelen/admin/edit/:id' exact 
              authenticationPath='/login'
              isAuthenticated={context.authObj.isAuthenticated()}
              component={EditArticleDetail} />
            <ProtectedRoute path='/artikelen/admin/new' exact 
              authenticationPath='/login'
              isAuthenticated={context.authObj.isAuthenticated()}
              component={NewArticleDetail} />

            <Route exact path="/" component={ArticleList} />
            <Route exact path="/artikelen/:category" component={ArticleList} />
            <Route exact path="/artikelen/:category/:id?/:name?" component={ArticleDetail} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/about" component={About} />
            <Route exact path="/authcheck" component={AuthCheck} />
          
            <Route component={Page404} />
          </Switch>
        </main>

        <aside className="rightSidebar">
          &nbsp;
        </aside>

        <footer>
          &copy; cmiddelweerd.nl 
        </footer>

      </Router>

    </div>

  )

}

export default Routes
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import Spinner from '../spinner';
import Error from '../error';

import './category-detail.css';

interface CategoryDetailProps {
    category: string,
}

const GET_CATEGORY = gql`
query getCategory($name: String) {
    categories(name: $name) {
        categoryId
        name
        url
        icon
        description
        articleCount
    }
}`;

function CategoryDetail(props: RouteComponentProps<CategoryDetailProps>) {    
    return (
        <Query query={GET_CATEGORY} variables={{name: props.match.params.category }}>
            {(result: any) => {
                const { data, loading, error } = result;

                if (loading) return <Spinner />
                if (error) return <Error error={error} />

                const category = data.categories[0];
                
                return  (
                    <div className={`category_detail`}>
                        <div className={`category_title`}>{category.name}</div>
                        <div className={`category_icon`}>
                            <img src={`../../icons/${category.icon}`} 
                                className="icon" alt={`Categorie ${category.name}`} />
                        </div>
                        <div className={`category_description`}>{category.description}</div>
                        <div className={`category_article_count`}>
                            Aantal artikelen: {category.articleCount}
                        </div>
                    </div>
                )
            }}
        </Query>)
}

export default CategoryDetail
interface EmptyProps {
  text?: any,
}
function Empty (props: EmptyProps) {
  return (
    <div>
      <span>{props.text !== null ? props.text : ""}</span>
    </div>
  )
}

export default Empty
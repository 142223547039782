import { useReducer } from 'react'
import ContentContext from './context'
import * as ACTIONS from '../store/actions'
import * as AuthReducer from '../store/reducers/auth_reducer'
import * as SearchReducer from '../store/reducers/search_reducer'
import Routes from '../routes'

import Auth from '../components/login/auth';


const auth = new Auth()

let searchString = '';


const ContextState = () => {
	/* Auth Reducer */
	const [stateAuthReducer, dispatchAuthReducer] =                      
		useReducer(AuthReducer.AuthReducer, AuthReducer.initialState)

	const handleLogin = () => {
		dispatchAuthReducer(ACTIONS.login_success())
	}

	const handleLogout = () => {
		dispatchAuthReducer(ACTIONS.login_failure())
	}

	// const handleAddProfile = (profile) => {
	//   dispatchAuthReducer(ACTIONS.add_profile(profile))
	// }

	// const handleRemoveProfile = () => {
	//   dispatchAuthReducer(ACTIONS.remove_profile())
	// }

	/* Search Reducer */
	const [stateSearchReducer, dispatchSearchReducer] = 
		useReducer(SearchReducer.SearchReducer, SearchReducer.initialState)

	const handleFormChange = (event: any) => {
		//console.log('Context state change: ' + event.target.value)
		//searchString = event.target.value;
		dispatchSearchReducer(ACTIONS.search_input_change(event.target.value))
	};

	const handleFormSubmit = (event: any) => {
		event.preventDefault();
		event.persist();

		searchString = event.target.useContext.value;

		dispatchSearchReducer(ACTIONS.search_input_submit(event.target.useContext.value))
	};

	//Handle authentication from callback
	const handleAuthentication = (props: any) => {
	  if(props.location.hash) {
		console.log('Context state handle auth')
//	    auth.handleAuth();
	  }

	}



	return(
		<div>
			<ContentContext.Provider
				value={{
					//Search Reducer
					searchString: searchString,
					useContextChangeState: stateSearchReducer.search_textChange,
					useContextSubmitState: stateSearchReducer.search_textSubmit,
					useContextSubmit: (event: any) => handleFormSubmit(event),
					useContextChange: (event: any) => handleFormChange(event),

					//Auth Reducer
					authState: stateAuthReducer.is_authenticated,
					profileState:  stateAuthReducer.profile,
					handleUserLogin: () => handleLogin(),
					handleUserLogout: () => handleLogout(),
					// handleUserAddProfile: (profile) => handleAddProfile(profile),
					// handleUserRemoveProfile: () => handleRemoveProfile(),

					//Handle auth
					handleAuth: (props) => handleAuthentication(props),
					authObj: auth
				}}>
				<Routes />
				</ContentContext.Provider>
		</div>
	)
}

export default ContextState;
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Event } from '../tracking';
import ContentContext from '../../context/context'

import './header.css'; 

interface HeaderProps { }

function Header(props: HeaderProps) {
    // const state = {
    //     navbarOpen: false,
    //     search: '',
    //     redirectTo: null,
    // }

    const context = useContext(ContentContext)    

    const isAuthorized = context.authObj.isAuthenticated();
    
    const filterArticles = (event: React.FormEvent<HTMLInputElement>) => {
        context.useContextChange(event);
    };

    return (<>
        <div className="logo">
          <Link to={`/`}
            onClick={() => {
              Event('Navigation', 'Logo', `Logo`);
              window.location.href= `/`;
             }}>
            .: cmiddelweerd.nl
          </Link>
        </div>
        
        <div className="hamburger"
        //   onClick={e => this.setState({ navbarOpen: !this.state.navbarOpen })}
        //   onKeyDown={e => this.setState({ navbarOpen: !this.state.navbarOpen })}
          role="button">
          <svg viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="20" rx="8" fill="rgb(241, 241, 241)"></rect>
            <rect y="30" width="100" height="20" rx="8" fill="white"></rect>
            <rect y="60" width="100" height="20" rx="8" fill="white"></rect>
          </svg>
        </div>
  
        <nav className="navigation">
          {/* <ul className={`nav_list ${this.state.navbarOpen ? `navigation_mobile_show` : null}`}> */}
          <ul>
            <li>
              <form>
                <input type="search" placeholder="Zoeken ..." onChange={filterArticles} />
              </form>
            </li>
            {/* {this.state.navbarOpen
              ? <></>
              : null} */}
            <li key={3} className="nav_item">
              {!isAuthorized
                ? <Link to="/login" className="nav_itemlink"
                    onClick={() => {
                      Event('Navigation', 'Log in', `Log in`);
                      context.authObj.login();
                      window.location.href= `/login`;
                    }}>log in</Link>
                : <Link to="/" className="nav_itemlink"
                    onClick={() => {
                      Event('Navigation', 'Log out', `Log out`);
                      context.authObj.logout();
                      window.location.href= `/`;
                    }}>Uitloggen</Link>
              }
            </li>
            {isAuthorized 
              ? 
                <li key={4} className="nav_item">
                  <Link to="/artikelen/admin" className="nav_itemlink"
                    onClick={() => {
                      window.location.href= `/artikelen/admin`;
                    }}>
                  Admin</Link>
                </li>
              : <></>
            }
            <li key={5} className="nav_item">
              <Link to={`/about/`} className="nav_itemlink"
                onClick={() => {
                  Event('Navigation', 'About', `About`)
                  window.location.href= `/about`;
                }}>Over</Link>
            </li>
          </ul>
        </nav>
  
        <div>
          <svg className="waves"
            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(251, 251, 251, 0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(33, 131, 196, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(209, 0, 174, 0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(41, 41, 41, 1)" />
            </g>
          </svg>
        </div>
    </>);
}

export default Header;

import { gql } from 'apollo-boost';
import { useContext } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import { ICategory } from '../../store/interfaces';
import { Event } from '../tracking';
import ContentContext from '../../context/context';
import Spinner from '../spinner';
import Error from '../error';

import './category-list.css';

interface CategoryListProps { }

const GET_CATEGORIES = gql`{
    categories {
        categoryId
        name
        isDefault
        url
    }
}`;

function CategoryList(props: CategoryListProps) {    
    const context = useContext(ContentContext)

    return (
        <Query query={GET_CATEGORIES}>
            {(result: any) => {
                const { data, loading, error } = result;

                if (loading) return <Spinner />
                if (error) return <Error error={error} />
                
                console.log('Hoeveel dan')
                console.log(data.categories.length)
                const categories = data.categories.filter((item: ICategory) => {
                    let content = item.name || '';
                    let searchString = (context.useContextChangeState || '').toLowerCase();

                    return content?.toLowerCase().search(searchString) !== -1 && item.isDefault === true;
                })

                return  (
                    <div className={`category_list`}>
                        <div className={`category_title`}>Menu</div>
                        { categories.map((category: any, categoryIndex: number) => 
                            <div className={`category_item`}
                            id={`category_item_${categoryIndex}`}
                            key={`category_item_${categoryIndex}`}>
                                <Link to={`/artikelen/${category.url}`}
                                    key={`category_${categoryIndex}`}
                                    onClick={() => {
                                        Event(
                                            `${category.name}`, 
                                            `Title ${category.name}`, 
                                            `Category title ${category.name}`);
                                            window.location.href= `/artikelen/${category.url}`;
                                    }}>{category.name}</Link>
                            </div>
                        )}
                    </div>
                )
            }}
        </Query>)
}

export default CategoryList
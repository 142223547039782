interface ErrorProps {
    error: any,
  }
  function Error (props: ErrorProps) {
      return (
        <div>
            <span>{props.error}</span>
        </div>
      )
  }
  
  export default Error
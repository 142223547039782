import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

export const makeApolloClient = (token: string) => {
    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_API_GRAPHQL
    });

    const cache = new InMemoryCache();

    const client = new ApolloClient({
        link: httpLink,
        cache: cache,
    });

    return client;
}